import { styled } from '@mui/material/styles'
import React, { MutableRefObject, useEffect, useRef } from 'react'
import {
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '@flock/flock-component-library'
import { Typography } from '@mui/material'
import { TrackedLink } from '@flock/shared-ui'

import { colors } from '../theme'
import { trackPage } from '../utils/analytics'
import PageWrapper from '../components/SharedComponents/PageWrapper'
import SectionLayout from '../components/SharedComponents/SectionLayout'

const InnerContainer = styled('div')({
  maxWidth: '67rem',

  [MOBILE_BREAKPOINT]: {
    width: '100%',
  },
})

const Title = styled(Typography)({
  color: colors.darkBlue,
  fontSize: '2rem',
  marginBottom: '16px',
})

const Section = styled('section')({
  paddingBottom: '2rem',
  [TABLET_BREAKPOINT]: {
    fontSize: '1rem',
  },
})

const Type = styled(Typography)({
  '&:before': {
    content: '"\\a"',
    whiteSpace: 'pre',
  },

  marginTop: '1rem',

  [MOBILE_BREAKPOINT]: {
    wordWrap: 'break-word',
  },
})

const Indented = styled(Typography)({
  marginLeft: '2rem',
})

const StyledOpacityLink = styled(TrackedLink)({
  textDecoration: 'underline',
  color: '#0C1D3f!important',
})

const PrivacyPolicy = () => {
  useEffect(() => {
    trackPage('privacy', {
      category: 'landing',
    })
  }, [])

  const collectInfoRef = useRef(null)
  const useInfoRef = useRef(null)
  const shareInfoRef = useRef(null)
  const yourChoicesRef = useRef(null)
  const otherSitesRef = useRef(null)
  const securityRef = useRef(null)
  const internationalDataRef = useRef(null)
  const childrenRef = useRef(null)
  const changesRef = useRef(null)
  const contactRef = useRef(null)

  const cookiesRef = useRef(null)
  const interestBasedAdvertisingRef = useRef(null)
  const complianceRef = useRef(null)

  const scrollToRef = (ref: MutableRefObject<any>) => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    })
  }

  return (
    <PageWrapper title="Overmoon | Privacy" trackingName="privacy">
      <SectionLayout name="privacy">
        <InnerContainer>
          <Title variant="h1">Privacy Policy</Title>
          <Section>
            <Type variant="h3">Effective as of April 26, 2020</Type>
            <Type sx={{ marginTop: '0.5rem' }}>
              This Privacy Policy describes how Overmoon, Inc. (“
              <b>Overmoon 721 Fund</b>,” “<b>we</b>”, “<b>us</b>” or “<b>our</b>
              ”) handles personal information that we collect through our
              digital properties that link to this Privacy Policy, including our
              website,{' '}
              <StyledOpacityLink href="https://exchange.overmoon.com">
                exchange.overmoon.com
              </StyledOpacityLink>{' '}
              (collectively, the “Service”), as well as through social media,
              our marketing activities, and other activities described in this
              Privacy Policy.
            </Type>
          </Section>
          <Section>
            <Type variant="h3">Index</Type>
            <Indented>
              <StyledOpacityLink onClick={() => scrollToRef(collectInfoRef)}>
                Personal information we collect
              </StyledOpacityLink>
            </Indented>
            <br />
            <Indented>
              <StyledOpacityLink onClick={() => scrollToRef(useInfoRef)}>
                How we use your personal information
              </StyledOpacityLink>
            </Indented>
            <br />
            <Indented>
              <StyledOpacityLink onClick={() => scrollToRef(shareInfoRef)}>
                How we share your personal information
              </StyledOpacityLink>
            </Indented>
            <br />
            <Indented>
              <StyledOpacityLink onClick={() => scrollToRef(yourChoicesRef)}>
                Your choices
              </StyledOpacityLink>
            </Indented>
            <br />
            <Indented>
              <StyledOpacityLink onClick={() => scrollToRef(otherSitesRef)}>
                Other sites and services
              </StyledOpacityLink>
            </Indented>
            <br />
            <Indented>
              <StyledOpacityLink onClick={() => scrollToRef(securityRef)}>
                Security
              </StyledOpacityLink>
            </Indented>
            <br />
            <Indented>
              <StyledOpacityLink
                onClick={() => scrollToRef(internationalDataRef)}
              >
                International data transfer
              </StyledOpacityLink>
            </Indented>
            <br />
            <Indented>
              <StyledOpacityLink onClick={() => scrollToRef(childrenRef)}>
                Children
              </StyledOpacityLink>
            </Indented>
            <br />
            <Indented>
              <StyledOpacityLink onClick={() => scrollToRef(changesRef)}>
                Changes to this Privacy Policy
              </StyledOpacityLink>
            </Indented>
            <br />
            <Indented>
              <StyledOpacityLink onClick={() => scrollToRef(contactRef)}>
                How to contact us
              </StyledOpacityLink>
            </Indented>
          </Section>
          <Section>
            <Type variant="h3">Personal information we collect</Type>
            <Type sx={{ marginTop: '0.5rem' }}>
              <b ref={collectInfoRef}>Information you provide to us.</b>{' '}
              Personal information you may provide to us through the Service or
              otherwise includes:
              <ul>
                <li>
                  <b>Contact data</b>, such as your first and last name, email
                  address, billing and mailing addresses, and phone number.
                </li>
                <li>
                  <b>Profile data</b>, such as the username and password that
                  you may set to establish an online account on the Service and
                  any other information that you add to your account profile.
                </li>
                <li>
                  <b>Communications</b> that we exchange with you, including
                  when you contact us through the Service, social media, or
                  otherwise.
                </li>
                <li>
                  <b>Transactional data</b>, such as information relating to or
                  needed to complete your transactions on or through the
                  Service.
                </li>
                <li>
                  <b>Marketing data</b>, such as your preferences for receiving
                  our marketing communications and details about your engagement
                  with them.
                </li>
                <li>
                  <b>User-generated content</b>, such as images, questions,
                  messages, and other content or information that you generate,
                  transmit, or otherwise make available on the Service, as well
                  as associated metadata. Metadata includes information on how,
                  when, where and by whom a piece of content was collected and
                  how that content has been formatted or edited. Metadata also
                  includes information that users can add or can have added to
                  their content, such as keywords, geographical or location
                  information, and other similar data.
                </li>
                <li>
                  <b>Financial data</b>, such as your stated income, economic
                  standing, tax return and other tax-related information.
                </li>
                <li>
                  <b>Government-issued identification numbers</b>, such as
                  national identification number (e.g. Social Security Number,
                  passport number), state or local identification number (e.g.,
                  driver&apos;s license or state ID number), and an image of the
                  relevant identification card. This data is collected and
                  analyzed for anti-money laundering and Know Your Customer
                  purposes.
                </li>
                <li>
                  <b>Payment information</b> needed to complete transactions,
                  including financial account numbers.
                </li>
                <li>
                  <b>Other data</b> not specifically listed here, which we will
                  use as described in this Privacy Policy or as otherwise
                  disclosed at the time of collection.
                </li>
              </ul>
            </Type>
            <Type>
              <b>Third-party sources.</b> We may combine personal information we
              receive from you with personal information we obtain from other
              sources, such as:
              <ul>
                <li>
                  <b>Public sources</b>, such as government agencies, public
                  records, social media platforms, and other publicly available
                  sources.
                </li>
                <li>
                  <b>Data providers</b>, such as information services and data
                  licensors that provide demographic and other information.
                </li>
                <li>
                  <b>Marketing partners</b>, such as joint marketing partners.
                </li>
              </ul>
            </Type>
            <Type>
              <b>Automatic data collection.</b> We, our service providers, and
              our business partners may automatically log information about you,
              your computer or mobile device, and your interaction over time
              with the Service, our communications and other online services,
              such as:
              <ul>
                <li>
                  <b>Device data</b>, such as your computer&apos;s or mobile
                  device&apos;s operating system type and version, manufacturer
                  and model, browser type, screen resolution, RAM and disk size,
                  CPU usage, device type (e.g., phone, tablet), IP address,
                  unique identifiers (including identifiers used for advertising
                  purposes), language settings, mobile device carrier,
                  radio/network information (e.g., Wi-Fi, LTE, 3G), and general
                  location information such as city, state or geographic area.
                </li>
                <li>
                  <b>Online activity data</b>, such as pages or screens you
                  viewed, how long you spent on a page or screen, the website
                  you visited before browsing to the Service, navigation paths
                  between pages or screens, information about your activity on a
                  page or screen, access times and duration of access, and
                  whether you have opened our emails or clicked links within
                  them.
                </li>
                <li>
                  <b>Location data</b> when you authorize the Service to access
                  your device&apos;s location.
                </li>
              </ul>
            </Type>
            <Type>
              <b>Cookies</b>. Some of our automatic data collection is
              facilitated by cookies and similar technologies.
            </Type>
            <Type>
              <b ref={cookiesRef}>Cookies and similar technologies</b>. Some of
              the automatic collection described above is facilitated by the
              following technologies:
              <ul>
                <li>
                  <b>Cookies</b>, which are small text files that websites store
                  on user devices and that allow web servers to record
                  users&apos; web browsing activities and remember their
                  submissions, preferences, and login status as they navigate a
                  site. Cookies used on our sites include both “session cookies”
                  that are deleted when a session ends, “persistent cookies”
                  that remain longer, “first party” cookies that we place and
                  “third party” cookies that our third-party business partners
                  and service providers place.
                </li>
                <li>
                  <b>Local storage technologies</b>, like HTML5, that provide
                  cookie-equivalent functionality but can store larger amounts
                  of data on your device outside of your browser in connection
                  with specific applications.
                </li>
                <li>
                  <b>Web beacons</b>, also known as pixel tags or clear GIFs,
                  which are used to demonstrate that a webpage or email address
                  was accessed or opened, or that certain content was viewed or
                  clicked.
                </li>
                <li>
                  <b>Session Replay Technologies</b>. We use third party
                  services provided by Hotjar that employ software code to
                  record users&apos; interactions with the Services in a manner
                  that allows us to watch DVR-like replays of those user
                  sessions. The replays include users&apos; clicks, mobile app
                  touches, mouse movements, scrolls and keystrokes/key touches
                  during those sessions. These replays help us diagnose
                  usability problems and identify areas for improvement. You can
                  learn more about Hotjar at{' '}
                  <StyledOpacityLink href="https://www.hotjar.com/legal/policies/privacy/">
                    https://www.hotjar.com/legal/policies/privacy/
                  </StyledOpacityLink>
                  .
                </li>
              </ul>
            </Type>
            <Type>
              <b>Data about others.</b> We may offer features that help users
              invite their friends or contacts to use the Service, and we may
              collect contact details about these invitees so we can deliver
              their invitations. Please do not refer someone to us or share
              their contact details with us unless you have their permission to
              do so.
            </Type>
          </Section>
          <Section>
            <Type variant="h3">How we use your personal information</Type>
            <Type>
              We may use your personal information for the following purposes or
              as otherwise described at the time of collection:
            </Type>
            <Type>
              <b ref={useInfoRef}>Service delivery.</b> We may use your personal
              information to:
              <ul>
                <li>
                  provide, operate and improve the Service and our business;
                </li>
                <li>
                  establish and maintain your user profile on the Service;
                </li>
                <li>
                  facilitate your invitations to friends who you want to invite
                  to join the Service;
                </li>
                <li>
                  enable security features of the Service, such as by sending
                  you security codes via email or SMS, and remembering devices
                  from which you have previously logged in;
                </li>
                <li>
                  communicate with you about the Service, including by sending
                  announcements, updates, security alerts, and support and
                  administrative messages;
                </li>
                <li>
                  understand your needs and interests, and personalize your
                  experience with the Service and our communications; and
                </li>
                <li>
                  provide support for the Service, and respond to your requests,
                  questions and feedback.
                </li>
              </ul>
            </Type>
            <Type>
              <b>Research and development</b>. We may use your personal
              information for research and development purposes, including to
              analyze and improve the Service and our business.
            </Type>
            <Type>
              <b>Marketing and advertising</b>. We, our service providers and
              our third-party advertising partners may collect and use your
              personal information for marketing and advertising purposes:
              <ul>
                <li>
                  <b>Direct marketing.</b> We may send you direct marketing
                  communications. You may opt-out of our marketing
                  communications as described in the{' '}
                  <StyledOpacityLink
                    onClick={() => scrollToRef(yourChoicesRef)}
                  >
                    Opt-out of marketing section below.{' '}
                  </StyledOpacityLink>
                </li>
                <li>
                  <b ref={interestBasedAdvertisingRef}>
                    Interest-based advertising
                  </b>
                  . Our third-party advertising partners may use cookies and
                  similar technologies to collect information about your
                  interaction (including the data described in the automatic
                  data collection section above) with the Service, our
                  communications and other online services over time, and use
                  that information to serve online ads that they think will
                  interest you. This is called interest-based advertising. We
                  may also share information about our users with these
                  companies to facilitate interest-based advertising to those or
                  similar users on other online platforms.
                </li>
              </ul>
            </Type>
            <Type>
              <b>
                To manage our recruiting and process employment applications
              </b>
              . We may use personal information, such as information submitted
              to us in a job application, to facilitate our recruitment
              activities and process employment applications, such as by
              evaluating a job candidate for an employment activity, and
              monitoring recruitment statistics.
            </Type>
            <Type>
              <b ref={complianceRef}>Compliance and protection</b>. We may use
              your personal information to:
              <ul>
                <li>
                  comply with applicable laws, lawful requests, and legal
                  process, such as to respond to subpoenas or requests from
                  government authorities;
                </li>
                <li>
                  protect our, your or others&apos; rights, privacy, safety or
                  property (including by making and defending legal claims);
                </li>
                <li>
                  audit our internal processes for compliance with legal and
                  contractual requirements or our internal policies;
                </li>
                <li>
                  enforce the terms and conditions that govern the Service; and
                </li>
                <li>
                  prevent, identify, investigate and deter fraudulent, harmful,
                  unauthorized, unethical or illegal activity, including by
                  using such data for anti-money laundering and Know Your
                  Customer purposes.
                </li>
              </ul>
            </Type>
            <Type>
              <b>With your consent</b>. In some cases, we may specifically ask
              for your consent to collect, use or share your personal
              information, such as when required by law.
            </Type>
            <Type>
              <b>To create anonymous, aggregated or de-identified data</b>. We
              may create anonymous, aggregated or de-identified data from your
              personal information and other individuals whose personal
              information we collect. We make personal information into
              anonymous, aggregated or de-identified data by removing
              information that makes the data identifiable to you. We may use
              this anonymous, aggregated or de-identified data and share it with
              third parties for our lawful business purposes, including to
              analyze and improve the Service and promote our business.
            </Type>
            <Type>
              <b>Cookies and similar technologies</b>. In addition to the other
              uses included in this section, we may use the{' '}
              <StyledOpacityLink onClick={() => scrollToRef(cookiesRef)}>
                Cookies and similar technologies
              </StyledOpacityLink>{' '}
              described above for the following purposes:
              <ul>
                <li>
                  <b>Technical operation</b>. To allow the technical operation
                  of the Service, such as by remembering your selections and
                  preferences as you navigate the site, and whether you are
                  logged in when you visit password protected areas of the
                  Service.
                </li>
                <li>
                  <b>Functionality</b>. To enhance the performance and
                  functionality of our services.
                </li>
                <li>
                  <b>Advertising</b>. To help our third-party advertising
                  partners collect information about how you use the Service and
                  other online services over time, which they use to show you
                  ads on other online services they believe will interest you
                  and measure how the ads perform.
                </li>
                <li>
                  <b>Analytics</b>. To help us understand user activity on the
                  Service, including which pages are most and least visited and
                  how visitors move around the Service, as well as user
                  interactions with our emails. For example, we use Google
                  Analytics, Hotjar, and Segment for this purpose. You can learn
                  more about Google Analytics and how to prevent the use of
                  Google Analytics relating to your use of our sites here:{' '}
                  <StyledOpacityLink href="https://tools.google.com/dlpage/gaoptout?hl=en">
                    https://tools.google.com/dlpage/gaoptout?hl=en
                  </StyledOpacityLink>
                  . You can learn more about Hotjar at{' '}
                  <StyledOpacityLink href="https://www.twilio.com/legal/privacy">
                    https://www.hotjar.com/legal/policies/privacy/
                  </StyledOpacityLink>
                  . You can learn more about Segment here:{' '}
                  <StyledOpacityLink href="https://www.twilio.com/legal/privacy">
                    https://www.twilio.com/legal/privacy
                  </StyledOpacityLink>
                  .
                </li>
              </ul>
            </Type>
            <Type>
              <b>Retention</b>. We generally retain personal information to
              fulfill the purposes for which we collected it, including for the
              purposes of satisfying any legal, accounting, or reporting
              requirements, to establish or defend legal claims, or for fraud
              prevention purposes. To determine the appropriate retention period
              for personal information, we may consider factors such as the
              amount, nature, and sensitivity of the personal information, the
              potential risk of harm from unauthorized use or disclosure of your
              personal information, the purposes for which we process your
              personal information and whether we can achieve those purposes
              through other means, and the applicable legal requirements.
            </Type>
            <Type>
              When we no longer require the personal information we have
              collected about you, we may either delete it, anonymize it, or
              isolate it from further processing.
            </Type>
          </Section>
          <Section>
            <Type variant="h3">How we share your personal information</Type>
            <Type>
              We may share your personal information with the following parties
              and as otherwise described in this Privacy Policy or at the time
              of collection.
            </Type>
            <Type>
              <b ref={shareInfoRef}>Affiliates</b>. Our corporate parent,
              subsidiaries, and affiliates, for purposes consistent with this
              Privacy Policy.
            </Type>
            <Type>
              <b>Service providers</b>. Third parties that provide services on
              our behalf or help us operate the Service or our business (such as
              anti-money laundering and Know Your Customer compliance, hosting,
              information technology, customer support, email delivery,
              marketing, consumer research and website analytics).
            </Type>
            <Type>
              <b>Payment processors</b>. Any bank account information you use to
              conduct a financial transaction on the Service is collected and
              processed directly by our payment processors, such as Plaid. Plaid
              may use your payment information in accordance with its privacy
              policy,{' '}
              <StyledOpacityLink href="https://plaid.com/legal">
                https://plaid.com/legal
              </StyledOpacityLink>
              .
            </Type>
            <Type>
              <b>Advertising partners</b>. Third-party advertising companies for
              the{' '}
              <StyledOpacityLink
                onClick={() => scrollToRef(interestBasedAdvertisingRef)}
              >
                interest-based advertising purposes
              </StyledOpacityLink>{' '}
              described above.
            </Type>
            <Type>
              <b>Third parties designated by you</b>. We may share your personal
              data with third parties where you have instructed us or provided
              your consent to do so. We will share personal information that is
              needed for these other companies to provide the services that you
              have requested.
            </Type>
            <Type>
              <b>Business and marketing partners</b>. Third parties with whom we
              co-sponsor events or promotions, with whom we jointly offer
              products or services, or whose products or services may be of
              interest to you.
            </Type>
            <Type>
              <b>Professional advisors</b>. Professional advisors, such as
              lawyers, auditors, bankers and insurers, where necessary in the
              course of the professional services that they render to us.
            </Type>
            <Type>
              <b>Authorities and others</b>. Law enforcement, government
              authorities, and private parties, as we believe in good faith to
              be necessary or appropriate for the{' '}
              <StyledOpacityLink onClick={() => scrollToRef(complianceRef)}>
                compliance and protection purposes
              </StyledOpacityLink>{' '}
              described above.
            </Type>
            <Type>
              <b>Business transferees</b>. Acquirers and other relevant
              participants in business transactions (or negotiations of or due
              diligence for such transactions) involving a corporate
              divestiture, merger, consolidation, acquisition, reorganization,
              sale or other disposition of all or any portion of the business or
              assets of, or equity interests in, Overmoon, Inc. or our
              affiliates (including, in connection with a bankruptcy or similar
              proceedings).
            </Type>
            <Type>
              <b>Other users and the public</b>. Your profile and other
              user-generated content (except for messages) may be visible to
              other users of the Service and the public. For example, other
              users of the Service or the public may have access to your
              information if you chose to make your profile or other personal
              information available to them through the Service, such as when
              you provide comments, reviews, survey responses, or share other
              content. This information can be seen, collected and used by
              others, including being cached, copied, screen captured or stored
              elsewhere by others (e.g., search engines), and we are not
              responsible for any such use of this information.
            </Type>
            <Type>
              We make commercially reasonable efforts to verify that the parties
              with whom our mobile application shares personal information
              provide a level of protection of personal information consistent
              with the practices described in this Privacy Policy, except that
              all such parties described above other than service providers and
              affiliates may, to the extent permitted by law, use personal
              information as described in their own privacy policies.
            </Type>
          </Section>
          <Section>
            <Type variant="h3">Your choices</Type>
            <Type>
              <b ref={yourChoicesRef}>Access or update your information.</b> If
              you have registered for an account with us through the Service,
              you may review and update certain account information by logging
              into the account.
            </Type>
            <Type>
              <b>Opt-out of marketing communications.</b> You may opt-out of
              marketing-related emails by following the opt-out or unsubscribe
              instructions at the bottom of the email, or by{' '}
              <StyledOpacityLink onClick={() => scrollToRef(contactRef)}>
                contacting us
              </StyledOpacityLink>
              . Please note that if you choose to opt-out of marketing-related
              emails, you may continue to receive service-related and other
              non-marketing emails.
            </Type>
            <Type>
              If you receive marketing text messages from us, you may opt out of
              receiving further marketing text messages from us by replying STOP
              to our marketing message.
            </Type>
            <Type>
              <b>Mobile location data.</b> You can disable our access to your
              device&apos;s precise geolocation in your mobile device settings.
            </Type>
            <Type>
              <b>Advertising choices</b>. You can limit use of your information
              for interest-based advertising by:
              <ul>
                <li>
                  <b>Browser settings</b>. Blocking third-party cookies in your
                  browser settings.
                </li>
                <li>
                  <b>Privacy browsers/plug-ins</b>. By using privacy browsers or
                  ad-blocking browser plug-ins that let you block tracking
                  technologies.
                </li>
                <li>
                  <b>Platform settings</b>. Google and Facebook offer opt-out
                  features that let you opt-out of use of your information for
                  interest-based advertising:
                  <ul>
                    <li>
                      Google:{' '}
                      <StyledOpacityLink href="https://adssettings.google.com/">
                        https://adssettings.google.com/
                      </StyledOpacityLink>
                    </li>
                    <li>
                      Facebook:{' '}
                      <StyledOpacityLink href="https://www.facebook.com/about/ads">
                        https://www.facebook.com/about/ads
                      </StyledOpacityLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Ad industry tools</b>. Opting out of interest-based ads
                  from companies participating in the following industry opt-out
                  programs:
                  <ul>
                    <li>
                      Network Advertising Initiative:{' '}
                      <StyledOpacityLink href="http://www.networkadvertising.org/managing/opt_out.asp">
                        https://www.networkadvertising.org/managing/opt_out.asp
                      </StyledOpacityLink>
                    </li>
                    <li>
                      Digital Advertising Alliance:{' '}
                      <StyledOpacityLink href="https://optout.aboutads.info">
                        https://optout.aboutads.info
                      </StyledOpacityLink>
                    </li>
                    <li>
                      AppChoices mobile app, available at{' '}
                      <StyledOpacityLink href="https://www.youradchoices.com/appchoices">
                        https://www.youradchoices.com/appchoices
                      </StyledOpacityLink>
                      , which will allow you to opt-out of interest-based ads in
                      mobile apps served by participating members of the Digital
                      Advertising Alliance.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Mobile settings</b>. Using your mobile device settings to
                  limit use of the advertising ID associated with your mobile
                  device for interest-based advertising purposes.
                </li>
              </ul>
            </Type>
            <Type>
              You will need to apply these opt-out settings on each device from
              which you wish to opt-out.
            </Type>
            <Type>
              We cannot offer any assurances as to whether the companies we work
              with participate in the opt-out programs described above.
            </Type>
            <Type>
              <b>Do Not Track</b>. Some Internet browsers may be configured to
              send “Do Not Track” signals to the online services that you visit.
              We currently do not respond to “Do Not Track” or similar signals.
              To find out more about “Do Not Track,” please visit{' '}
              <StyledOpacityLink href="https://www.allaboutdnt.com">
                https://www.allaboutdnt.com
              </StyledOpacityLink>
              .
            </Type>
            <Type>
              <b>Declining to provide information</b>. We need to collect
              personal information to provide certain services. If you do not
              provide the information we identify as required or mandatory, we
              may not be able to provide those services.
            </Type>
            <Type>
              <b>Delete your content or close your account</b>. You can choose
              to delete certain content through your account. If you wish to
              request to close your account, please{' '}
              <StyledOpacityLink onClick={() => scrollToRef(contactRef)}>
                contact us
              </StyledOpacityLink>
              .
            </Type>
          </Section>
          <Section ref={otherSitesRef}>
            <Type variant="h3">Other sites and services</Type>
            <Type>
              The Service may contain links to websites, mobile applications,
              and other online services operated by third parties. In addition,
              our content may be integrated into web pages or other online
              services that are not associated with us. These links and
              integrations are not an endorsement of, or representation that we
              are affiliated with, any third party. We do not control websites,
              mobile applications or online services operated by third parties,
              and we are not responsible for their actions. We encourage you to
              read the privacy policies of the other websites, mobile
              applications and online services you use.
            </Type>
          </Section>
          <Section ref={securityRef}>
            <Type variant="h3">Security</Type>
            <Type>
              We employ a number of technical, organizational and physical
              safeguards designed to protect the personal information we
              collect. However, security risk is inherent in all internet and
              information technologies and we cannot guarantee the security of
              your personal information.
            </Type>
          </Section>
          <Section ref={internationalDataRef}>
            <Type variant="h3">International data transfer</Type>
            <Type>
              We are headquartered in the United States and may use service
              providers that operate in other countries. Your personal
              information may be transferred to the United States or other
              locations where privacy laws may not be as protective as those in
              your state, province, or country.
            </Type>
          </Section>
          <Section ref={childrenRef}>
            <Type variant="h3">Children</Type>
            <Type>
              The Service is not intended for use by anyone under 18 years of
              age. If you are a parent or guardian of a child from whom you
              believe we have collected personal information in a manner
              prohibited by law, please{' '}
              <StyledOpacityLink onClick={() => scrollToRef(contactRef)}>
                contact us
              </StyledOpacityLink>
              . If we learn that we have collected personal information through
              the Service from a child without the consent of the child&apos;s
              parent or guardian as required by law, we will comply with
              applicable legal requirements to delete the information.
            </Type>
          </Section>
          <Section ref={changesRef}>
            <Type variant="h3">Changes to this Privacy Policy</Type>
            <Type>
              We reserve the right to modify this Privacy Policy at any time. If
              we make material changes to this Privacy Policy, we will notify
              you by updating the date of this Privacy Policy and posting it on
              the Service or other appropriate means. Any modifications to this
              Privacy Policy will be effective upon our posting the modified
              version (or as otherwise indicated at the time of posting). In all
              cases, your use of the Service after the effective date of any
              modified Privacy Policy indicates your acceptance of the modified
              Privacy Policy.
            </Type>
          </Section>
          <Section ref={contactRef}>
            <Type variant="h3">How to contact us</Type>
            <Type>
              <ul>
                <li>
                  <b>Email</b>: 721exchange@overmoon.com
                </li>
                <li>
                  <b>Mail</b>:<br />
                  Overmoon, Inc.
                  <br />
                  655 Irwin Street #1034
                  <br />
                  San Rafael, CA 94901
                  <br />
                </li>
                <li>
                  <b>Phone</b>: 850-787-9025
                </li>
              </ul>
            </Type>
          </Section>
        </InnerContainer>
      </SectionLayout>
    </PageWrapper>
  )
}

export default PrivacyPolicy
